export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Banner',
    route: 'banner',
    icon: 'FileTextIcon',
  },
  {
    title: 'Usuarios',
    route: 'users',
    icon: 'UserIcon',
  },
  {
    title: 'Paises',
    route: 'countries',
    icon: 'GlobeIcon',
  },

  {
    title: 'Configuraciones',
    route: 'account-setting',
    icon: 'SettingsIcon',
  },
  {
    title: 'Reset Password',
    route: 'auth-reset-password',
    target: '_blank',
  },

  {
    header: 'Miscellaneous',
    icon: 'FileIcon',
    children: [
      {
        title: 'Coming Soon',
        route: 'misc-coming-soon',
        target: '_blank',
      },
      {
        title: 'Not Authorized',
        route: 'misc-not-authorized',
        target: '_blank',
      },
      {
        title: 'Under Maintenance',
        route: 'misc-under-maintenance',
        target: '_blank',
      },
      {
        title: 'Error',
        route: 'misc-error',
        target: '_blank',
      },
    ],
  },
]
